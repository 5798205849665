import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import HeroHeader from "../components/sections/hero-header"
import {
  Container,
  Section,
  ImageContainer,
  SectionTitle,
  EmphasisTitle,
  EmphasisDescription,
  EmphasisContainer,
} from "../components/global"
import ContactForm from "../components/contact-form"
import backShape from "../images/back-shape-2.svg"
import Img from "gatsby-image"

const Product = ({ data, location }) => {
  const productData = data.productsJson
  const images = data.images

  productData.features.map((feature) => {
    let imageFluid = null
    if (feature.image) {
      const featureImageIndex = images.edges.findIndex(
        (y) => y.node.name === feature.image.split(".").slice(0, -1).join(".")
      )
      if (featureImageIndex >= 0) {
        imageFluid = images.edges[featureImageIndex].node.childImageSharp.fluid
      }
    }
    feature.imageFluid = imageFluid
    return imageFluid
  })

  return (
    <Layout
      title={productData.seoTitle}
      description={productData.seoDescription}
      location={location}
    >
      <HeroHeader
        title={productData.title}
        subTitle={productData.shortDescription}
        backgroundImage={productData.backgroundImage}
        foregroundImage={productData.foregroundImage}
        middleImage={productData.middleImage}
      ></HeroHeader>
      {productData.description && (
        <Section type="accent">
          <EmphasisContainer narrow="true">
            {productData.title && (
              <EmphasisTitle>What is {productData.title}</EmphasisTitle>
            )}
            <EmphasisDescription>{productData.description}</EmphasisDescription>
          </EmphasisContainer>
        </Section>
      )}
      <Section type="secondary">
        <Container>
          {productData.title && (
            <SectionTitle>{productData.title} features</SectionTitle>
          )}
          <FeaturesList>
            {productData.features.map((feature) => (
              <FeatureItem>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureImage>
                  <ImageContainer>
                    {feature.imageFluid && (
                      <Img
                        fluid={feature.imageFluid}
                        title={feature.title}
                        alt={feature.title}
                      />
                    )}
                  </ImageContainer>
                </FeatureImage>
                <FeatureInfoContainer>
                  <FeatureDescription>{feature.description}</FeatureDescription>
                </FeatureInfoContainer>
              </FeatureItem>
            ))}
          </FeaturesList>
        </Container>
      </Section>
      <Section
        type="accent"
        style={{
          backgroundImage: `url(${backShape})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          backgroundSize: "cover",
        }}
      >
        <Container narrow="true" shadow="true">
          <ContactForm
            subject={productData.title}
            title={
              "Want to know more about " +
              productData.title +
              "? Let us answer your questions!"
            }
          />
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    productsJson(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      category
      description
      shortDescription
      backgroundImage
      foregroundImage
      middleImage
      fields {
        slug
      }
      features {
        description
        title
        image
      }
    }
    images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Product

const FeatureItem = styled.div`
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    width: calc(33.33% - 20px);
  }
  padding: 0 20px;
  margin-bottom: 20px;
`

const FeaturesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const FeatureInfoContainer = styled.div``

const FeatureTitle = styled.h4`
  margin-top: 0;
  text-align: center;
  font-size: 1.5rem;
  /*line-height: 2rem;*/
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    min-height: 4rem;
  }
`

const FeatureDescription = styled.p`
  text-align: center;
  margin-bottom: 25px;
`

const FeatureImage = styled.div`
  margin: 0 15px;
  figure {
    width: 170px;
    height: 170px;
    padding-bottom: 0;
  }
`
